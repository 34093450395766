@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: darkdsf) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

.pages h1{
  font-size: 1.5rem;
  line-height: 2rem;
  color: #25377d;
}

.pages p{
  font-family: "Poppins";
    line-height: 26px;
    font-size: 14px;
}

.pages ul{
  list-style: disc;
  margin-left: 20px;
  padding:10px 20px 10px 20px;
}

body {
  color: rgb(var(--foreground-rgb));
      background: #fff;
}

@layer components {
  .border-antialised {
    box-shadow:
      0 0 1px 0px white inset,
      0 0 1px 0px white;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: darkdsf) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: "#fefefe";
}

@layer components {
  .border-antialised {
    box-shadow:
      0 0 1px 0px white inset,
      0 0 1px 0px white;
  }
}
.content {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [full-start] 1fr[content-start slider-start] 10fr[content-end] 1fr[slider-end full-end];
}

.header_grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [full-start] 1fr[content-start logo-start]5fr[logo-end menu-start]auto[menu-end content-end] 1fr[full-end];
  /*min-height: 100vh;*/
}

.logo {
  grid-column: logo;
}

.menu {
  grid-column: menu;
}

.main_slider {
  grid-column: content;
}

.slider {
  grid-column: slider;
}

.heading {
  grid-column: content;
}

.conteiner {
  grid-column: content;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 5px;
}

.teknopark {
  grid-column: content;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.contact {
  grid-column: content;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [form-start]1fr[form-end map-start] 2fr[map-end];
}

.contact_form {
  grid-column: form;
}

.map {
  grid-column: map;
}

.footer_menu {
  grid-column: content;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-gap: 5px;
}

.footer {
  grid-column: content;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}



@media (max-width: 768px) {
  .content {
    grid-template-columns: [full-start slider-start content-start] 1fr [content-end slider-end full-end];
  }

  .header_grid {
    grid-template-columns: [full-start content-start logo-start menu-start]1fr[logo-endmenu-end content-end full-end];
    grid-template-rows: [logo-start]1fr[logo-end menu-start] 1fr [menu-end];
    min-height: auto;
  }
  .logo {
    grid-column: logo;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu {
    grid-column: menu;
  }

  .conteiner {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .teknopark {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .contact {
    grid-template-columns: [form-start map-start]1fr[map-end form-end];
  }

  .footer_menu {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .content {
    grid-template-columns: [full-start] 1fr[content-start slider-start] 10fr[content-end] 1fr[slider-end full-end];
    min-height: auto;
  }

  .conteiner {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .teknopark {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .contact {
    grid-template-columns: [form-start map-start]1fr[map-end form-end];
  }

  .footer_menu {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
